var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-cover-header"},[_c('div',{staticClass:"top-header"},[_c('div',{staticClass:"left-box"},[(_vm.showLogo)?_c('img',{staticClass:"logo",attrs:{"src":"/small-logo.png","alt":""}}):_c('div',{staticClass:"welcome"},[_vm._v(" 欢迎来到岭湃客！")])]),_c('div',{staticClass:"right-box"},[_c('router-link',{staticClass:"link-text",attrs:{"tag":"div","to":"/"}},[_vm._v("返回首页")]),_c('router-link',{staticClass:"nav-link",attrs:{"tag":"div","to":"/messageManagement/messageList"}},[_c('a-popover',{attrs:{"placement":"bottomRight","autoAdjustOverflow":true,"arrowPointAtCenter":false,"overlayClassName":"header-notice-wrapper","overlayStyle":{ width: '364px', top: '50px' }}},[_c('template',{slot:"content"},[_c('div',{staticClass:"header-title"},[_c('img',{staticClass:"alarm",attrs:{"src":"/notice_icon.png","alt":""}}),_c('span',[_vm._v("消息中心")])]),_c('div',{staticClass:"notice-content-item"},[_c('span',{staticClass:"left-label"},[_c('span',{staticClass:"label"},[_vm._v("系统消息")]),(_vm.countInfo.msgCategory_1)?_c('a-badge',{attrs:{"count":_vm.countInfo.msgCategory_1 || 0,"overflow-count":999,"number-style":{ backgroundColor: '#EE4261' }}}):_c('a-badge',{attrs:{"showZero":true,"count":"0","number-style":{
                    backgroundColor: '#EFEFEF',
                    color: 'rgba(0, 0, 0, .25)',
                    boxShadow: '0 0 0 1px #d9d9d9 inset',
                  }}})],1),_c('img',{staticClass:"showInfo",attrs:{"src":"/showInfo.png","alt":""},on:{"click":function($event){return _vm.handleJump(1)}}})]),_c('div',{staticClass:"notice-content-item"},[_c('span',{staticClass:"left-label"},[_c('span',{staticClass:"label"},[_vm._v("服务消息")]),(_vm.countInfo.msgCategory_2)?_c('a-badge',{attrs:{"count":_vm.countInfo.msgCategory_2 || 0,"overflow-count":999,"number-style":{ backgroundColor: '#EE4261' }}}):_c('a-badge',{attrs:{"showZero":true,"count":"0","number-style":{
                    backgroundColor: '#EFEFEF',
                    color: 'rgba(0, 0, 0, .25)',
                    boxShadow: '0 0 0 1px #d9d9d9 inset',
                  }}})],1),_c('img',{staticClass:"showInfo",attrs:{"src":"/showInfo.png","alt":""},on:{"click":function($event){return _vm.handleJump(2)}}})]),_c('div',{staticClass:"notice-content-item"},[_c('span',{staticClass:"left-label"},[_c('span',{staticClass:"label"},[_vm._v("客户消息")]),(_vm.countInfo.msgCategory_3)?_c('a-badge',{attrs:{"count":_vm.countInfo.msgCategory_3 || 0,"overflow-count":999,"number-style":{ backgroundColor: '#EE4261' }}}):_c('a-badge',{attrs:{"showZero":true,"count":"0","number-style":{
                    backgroundColor: '#EFEFEF',
                    color: 'rgba(0, 0, 0, .25)',
                    boxShadow: '0 0 0 1px #d9d9d9 inset',
                  }}})],1),_c('img',{staticClass:"showInfo",attrs:{"src":"/showInfo.png","alt":""},on:{"click":_vm.handleJumpChat}})])]),_c('a-badge',{attrs:{"count":_vm.messageCount,"number-style":{ backgroundColor: '#EE4261' },"overflow-count":999}},[_c('div',{staticClass:"link-text"},[_vm._v("消息")])])],2)],1),_c('Menus',{staticClass:"noLeft",attrs:{"showEntry":false}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }